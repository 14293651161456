@import './../../styles/_colors.scss';
@import './../../styles/_common.scss';

.partnerPopup{
    max-width: 700px;
    min-width: 600px;
}
.popup {
    max-width: 700px;
    min-height: 350px;
    min-width: 600px;
}

.marginTopCheckbox {
    margin-top: 2rem;
}

.selectionUnable {
    color: $lightGray;
    margin-top: 5px;
}

.spinnerInModal{
    display: inline-block;
    margin-left: 10px;
}

.rejected {
    background: $alizarinCrimson;
    color: white;
  
    &:hover {
      background: $alizarinCrimson;
    }
}

.partSelectionCombo {
    min-width: 290px;
}
.menuItemHeader {
    color: $black;
}
.toolTip {
    margin: 5px;
}
.warningIcon {
    color: $persianRed;
    font-size: 16px;
    margin-left: 5px;
}
.elementToolTip {
    margin-bottom: 20px;
    float: left;
    margin-top: 5px;
}
.toolTipIcon {
    width: 20px;
    height: 20px;
}