@import "./../../styles/_colors.scss";

.button {
  background-color: $black;
  border: 0;
  color: $white;
  cursor: pointer;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 32px;
  letter-spacing: normal;
  line-height: 1.43;
  margin-left: 22px;
  margin-top: 30px;
  width: 120px;
}

.continueDisabled {
  cursor: not-allowed;
  background-color: $gray;
  color: $white;
}

.desc {
  color: $black;
  font-size: 14px;
  height: 19px;
  letter-spacing: 0;
}

.disabled {
  cursor: not-allowed !important;
}

.inputContainer {
  align-items: center;
  display: flex;
  margin-left: 0;
  padding-top: 15px;
}

.inputContainer label {
  color: $black;
  font-size: 14px;
  line-height: 20px;
}

.inputContainer p{
  position: absolute;
}

.zipCodeContainer {
  padding: 20px 0;
}
