@import "./../../styles/_colors.scss";

.addressLabel {
    font-weight: bold;
}

.addressSection {
    margin-bottom: 10px;
}

.body {
    line-height: 1.67;
    font-size: 12px;
    padding: 10px;
}

.header {
    align-items: center;
    display: flex;
    flex: 100%;
    justify-content: space-between;
    width: 100%;
}

