@import "./../../styles/_colors.scss";

.fontSize {
  font-size: 14px;
  line-height: 20px;
  width: 100px;
}

.fontSizeSmall {
  font-size: 12px;
  line-height: 20px;
}

.fontBold {
  font-weight: bold;
}

.planDetail {
  padding: 22px 0;
}

.plan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 25px;
  border: 1px solid $nobel;
  height: 50px;
  background: $alabaster;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.typeCodeWidth {
  width: 100px;
}

.descCodeWidth {
  width: 400px;
}

.planWidth {
  width: 200px;
}

.claims {
  width: 940px;
  padding-top: 20px;
}

.claimSec {
  width: 100%;
}

.infoIcon {
  color: #000000;
  font-size: 1rem;
  margin-left: 1rem;
  vertical-align: bottom;
  cursor: pointer;
}