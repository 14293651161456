@import "./../../styles/_colors.scss";

.divTable {
  border: none;
  display: table;
  width: 100%;
}

.divTableRow {
  display: table-row;
}

.divTableCell, .divTableHead {
  border: none;
  display: table-cell;
  padding: 10px 0;
  text-align: left;
}

.divTableCell:first-child {
  width: 55%;
}

.divTableBody {
  border: none;
}

.headerTitle {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: $black;
  border-bottom: 1px solid $black;
}

.cellValue {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: $black;
  padding: 15px 0 20px 0;
}

.label {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: $black;
  white-space: nowrap;
}

.span {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: $black;
  padding: 0 0 10px 10px;
}

.extraInformationContainer {
  border: none;
  flex-direction: column;
  overflow-wrap: break-word;
}

.informationContainer {
  display: flex;
  flex-direction: row;
  word-break: break-all;
}

.lineSeparator {
  border-bottom: 1px solid $nobel;
}

.thead {
  background: none;
}

.tableInfo {
    table-layout: fixed;
}
