@import './../../styles/_colors.scss';
@import './../../styles/_common.scss';

.actionButton {
    font-size: 12px;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-left: 8px;
}

.additionalInfo {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    padding-top: 23px;
}
.columnAlignment {
    margin: auto;
    position: relative;
}

.label {
    font-size: 12px;
    font-weight: 600;
}

.shippingMethod {
    padding: 0px 8px 40px 8px;
    display: flex;
}

.borderDown {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}
.subHeaders {
    font-weight: bold;
}
