@import "./../../styles/_colors.scss";

.authInvite,
.topBar {
  width: 100%;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: left;
  margin-bottom: 30px;
  align-self: center;
  width: 940px;
}

.imageContainer {
  display: flex;
  height: auto;
  width: 80%;
}

.title {
  font-size: 30px;
  line-height: 28px;
  margin-bottom: 20px;
}

.semiTitle {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
}

.dropdownDesc {
  font-size: 14px;
  line-height: 19.2px;
  margin-top: 30px;
}

.dropdownContainer {
  margin-top: 20px;
  border-bottom: 1px solid $nobel;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table thead {
  background: #999;
}

table ,td ,th {
  border: 1px solid $black;
  padding: 5px;
  font-size: 16px;
}