@import "./../../styles/_colors.scss";

.bodyContainer {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: left;
  margin-bottom: 70px;
  align-self: center;
  width: 940px;
}

.backToDeviceDetail {
  background-color: $black;
  font-size: 14px;
  line-height: 20px;
  height: 32px;
  padding: 10px;
}

.buttons {
  display: flex;
  padding: 24px 0;
}

.spinnerStyle {
  margin-bottom: 6px;
  margin-left: 10px;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.buttonContainer button {
  width: 120px;
  height: 32px;
  border: solid 2px $black;
  color: $black;
  font-size: 14px;
  line-height: 20px;
  background-color: $white;
}

.buttonContainer img {
  padding-left: 5px;
}

.cancel {
  background-color: $white;
  font-size: 14px;
  line-height: 20px;
  height: 32px;
  width: 120px;
  margin-left: 20px;
  border: 2px solid $black;
}

.choiceGroup {
  padding-top: 29px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 20px;
}

.description {
  display: flex;
  padding: 32px 0 23px 0;
}

.detail {
  width: 930px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: $black;
  line-height: 28px;
}

.detailLeftDesc {
  padding-top: 14px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  color: $black;
  height: 32px;
  width: 417px;
}

.detailRight img {
  float: left;
  width: 25px;
  height: 30px;
}

.detailRightPlan {
  float: left;
  padding-left: 4px;
}

.error {
  color: $alizarinCrimson;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: expanded;
  line-height: 1.6;
  letter-spacing: normal;
}

.errorContainer {
  width: 700px;
}

.errorDesc {
  padding-top: 14px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  color: $persianRed;
  height: 32px;
  width: 417px;
}

.expired {
  color: $red;
  font-weight: bold;
}

.heading {
  font-size: 24px;
  line-height: 28px;
  color: $black;
  height: 28px;
  width: 201px;
}

.headingUpdated {
  font-size: 24px;
  line-height: 28px;
  color: $black;
  height: 28px;
  width: 400px;
}

.headingPlan {
  font-size: 16px;
  line-height: 14px;
  height: 22px;
  letter-spacing: 0;
}

.headingPlanDetail {
  font-size: 11px;
  line-height: 14px;
}

.headingPlanDetail span {
  font-weight: bold;
  padding-left: 5px;
}

.labels {
  font-size: 12px;
  line-height: 18px;
}

.leftDesc img {
  width: 80px;
  height: 60px;
}

.middleDesc {
  padding-left: 30px;
  font-size: 11px;
  line-height: 18px;
  color: $black;
  width: 286px;
}

.purchaseLabelDesc {
  padding-bottom: 8px;
}

.productDetailDescription {
  margin: 20px 0;
  border-bottom: 1px solid $grey;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.radioContainer {
  display: flex;
  padding-bottom: 18px;
}

.radioContainer > div.labels > label {
  display: block;
}

.rightDesc {
  padding-left: 64px;
  font-size: 11px;
  line-height: 18px;
  color: $black;
  width: 210px;
}

.sectionContainer {
  border-bottom: 1px solid $nobel;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  &:last-child {
    border-bottom: none;
  }
}

.spanText {
  font-weight: bold;
}

.serialCountry {
  font-weight: bold;
  padding-left: 9px;
}

.submit {
  background-color: $black;
  font-size: 14px;
  line-height: 20px;
  height: 32px;
  width: 120px;
}

.submitDisabled {
  cursor: not-allowed;
  background-color: $gray;
  color: $white;
}

.title {
  color: $black;
  line-height: 28px;
  font-size: 30px;
  padding-bottom: 28px;
}

.topBar {
  width: 100%;
}

.updateContainer {
  margin: 20px 185px 20px 142px;
  border-bottom: 1px solid $grey;
  padding-bottom: 20px;
}

.warrantyUpdate {
  width: 100%;
}

.zipCode {
  display: flex;
}

.zipCode img {
  padding-left: 5px;
}

.zipCode span {
  font-weight: bold;
  padding-left: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
