@import './../../../styles/_colors.scss';
@import './../../../styles/_common.scss';

.addressContainer {
    font-family: $font-stack-all;
    font-size: 14px;
    width: 100%;
    align-self: center;
}

.buttonAlign {
    align-self: flex-end;
    margin-left: auto;
}

.commonFocus{
    &:focus{
        outline-offset: 3px;
    }
}

.continueButton {
    cursor: pointer;
    font-size: 14px;
    height: 32px;
    line-height: 1.43;
    width: 125px;
    &:focus::after {
        border-color: $white ;
        border-width: 1.5px;
        border-style: solid ;
      }
}

.buttonContainer {
    display: flex;
    align-content: flex-end;
    align-items: center;
    margin-top: 30px;
    padding-top: 10px;
    border-top: 1px solid $nobel;
}

.fieldContainer {
    width: inherit;
}

.headerContainer {
    color: $black;
    margin-bottom: 30px;
}

.message {
    font-size: 11px;
}

.popup {
    min-width: 600px;
    max-width: 700px;
    min-height: 350px;
}

.spinnerStyle {
    margin: 4px;
    align-self: flex-end;
}
