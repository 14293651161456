@import "./../../../styles/_colors.scss";
@import "./../../../styles/_common.scss";

.accessoryHeading {
  @extend .h3Heading;
  margin-top: 20px;
}

.accessorySection {
  border-bottom: 1px solid $nobel;
  padding-bottom: 20px;
  padding-top: 15px;
}

.changeSelection {
  background-color: $white;
  border: 2px solid;
  color: $black;
  cursor: pointer;
  &:disabled {
    background-color: $gray;
    cursor: not-allowed;
    color: $white;
  }
}

.continueButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid $nobel;
}

.continueButton {
  align-self: flex-end;
  background-color: $black;
  color: $white;
  font-size: 14px;
  height: 32px;
  line-height: 20px;
  padding: 0 10px;
  width: 125px;

  &:hover {
    background-color: $black;
    color: $white;
  }

  &:disabled {
    background-color: $gray;
    cursor: not-allowed;
  }
  &:focus::after {
    border-color: $azureRadiance !important;
  }
}

.country {
  font-weight: 500;
  font-size: 16px;
  padding-left: 5px;
}

.disabled {
  background-color: $gray;
  border: none;
  color: $white;
  cursor: not-allowed;
}

.deleteIcon {
  cursor: pointer;
  font-size: 17px;
  height: 20px;
  width: 20px;
}

.headerSection {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.offerTableContainer {
  width: inherit;
  border-top: 1px solid $nobel;
}

.parentSection {
  padding-bottom: 20px;
}

.problemInfoSectionContainer {
  display: table;
  margin-left: 5px;
}

.problemInfoSection {
  display: table-row;
  flex-direction: row;
}

.problemInfoLabel {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: $doveGray;
  display: table-cell;
  padding-top: 5px;
}

.problemInfoSpan {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  display: table-cell;
  padding-left: 15px;
  padding-top: 5px;
}

.partOfferContainer {
  margin-top: 10px;
}

// .repairItemContainer {
//   width: 940px;
// }

.sectionSeparator {
  border-top: 1px solid $nobel;
}

.spinner {
  margin-top: 20px;
}

.serviceOptionsDesc {
  display: flex;
  align-items: center;
  width: 90%;
  font-size: 16px;
  padding-bottom: 20px;
}
