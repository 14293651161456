@import "./../../styles/_colors.scss";

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.buttonAndSpinnerContainer{
    display: flex;
    flex-direction: row;
}

.error {
    color: $persianRed;
    white-space: nowrap;
}

.fields {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}

.registerDevice {
    padding: 27px 0;
}

.registerInputs {
    display: flex;
    flex-direction: column;
    margin-top: 28px;
}

.submit {
    background-color: $black;
    font-size: 14px;
    height: 32px;
    line-height: 20px;
    margin-top: 28px;
    width: 130px;
}

.spinner{
    margin-top: 28px;
    padding-left: 10px;
}

.titleHeading {
    color: $black;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    margin: 0;
}