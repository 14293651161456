@import "./../../styles/_colors.scss";

.claimOrder {
    color: black;
    font-size: 14px;
    width: 939px;
}

.header {
    align-items: center;
    display: flex;
    flex: 100%;
    justify-content: flex-start;
    padding-right: 20px;
    width: 100%;
}

.order {
    display: flex;
    margin-right: 70px;
    white-space: nowrap;
}

.orderNumber {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 175px;
}

.orderNumberLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 175px;
}

.detailLink {
    text-decoration: none;
}

.status {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    min-width: 130px;
}

.statusIcon {
    align-items: center;
    background: $japaneseLaurel;
    border-radius: 50%;
    color: $white;
    display: flex;
    font-size: 10px;
    height: 18px;
    justify-content: center;
    margin-right: 9px;
    width: 19px;
}

.statusLabel {
    margin-right: 9px;
}

.statusState {
    font-weight: bold;
    text-transform: capitalize;
}