@import "./../../styles/_colors.scss";

.buttons {
    text-align: right;
    margin-top: 44px;
}

.buttons button {
    &:first-child {
        margin-right: 10px;
    }
}
.container {
    width: 447px;
    border: 1px solid $denimBlue;
    outline: none;
    box-shadow: none;
    padding: 12px;
}

.cancel {
    background-color: $white;
    border: 2px solid $black;
    color: $black;
    font-size: 14px;
    line-height: 20px;
    width: 120px;

    &:hover {
        background-color: $white;
        color: $black;
    }
    &:focus{
        outline: double;
    }
}

.dropdown {
    width: 197px;
    border: solid 2px rgba(0, 0, 0, 0.4);
    margin-left: 10px;
}

.error {
    color: $persianRed;
}

.header {
    font-size: 20px;
    padding-bottom: 5px;
}

.headerDesc {
    font-size: 14px;
    margin-bottom: 30px;
}

.save {
    background-color: $black;
    font-size: 14px;
    line-height: 20px;
    width: 120px;

    &:hover {
        background-color: $black; 
    }
}