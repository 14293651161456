@import './../../styles/_colors.scss';
@import './../../styles/_common.scss';

.hidden {
    display: none !important;
}

.displayBlock {
    display: block !important;
}

.container {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.header {
    color: $black;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 1.17;
    margin-bottom: 20px;
    margin-top: 0px;
}

.grid {
    border: 1px solid $gray;
    padding: 0px;
}

.gridMainRow {
    margin: 20px 200px 50px 50px;
}

.rowHeader {
    color: blue;
}

.divLeft {
    float: left;
    font-size: 14pt;
    width: 70%;
}

.divRight {
    float: right;
}

.ancDownloadButton {
    cursor: pointer;
    color: black;
    margin-top: 2px;
    font-weight: bold;
    font-size: 20px;
}

.downloadButton {
    background-color: rgb(0, 120, 212);
    border: 2px solid;
    color: #fff;
    cursor: pointer;
    font-family: 'Segoe UI';
    font-size: 16.45px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1;
    max-height: 52px;
    max-width: 300px;
    min-height: 44px;
    min-width: 196px;
    overflow: hidden;
    padding: 0 10px;
}

.inpUpload {
    width: inherit;
    font-size: 15px;
}

::-webkit-file-upload-button {
    background: #0078d4;
    color: #fff;
    cursor: pointer;
    border: 2px solid;
    font-family: 'Segoe UI';
    font-size: 16.45px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    max-height: 52px;
    max-width: 300px;
    min-height: 44px;
    min-width: 156px;
}

.modalCloseIcon {
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    display: inline-block;
}

.modalCloseIcon i {
    font-size: 18px;
    font-weight: bold;
}

.validationMsg {
    color: red;
    font-size: 13pt;
}