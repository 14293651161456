@import "./../../styles/_colors.scss";

.pizzaTracker {
    display: inline-block;
    font-size: 10px;
}

.stage {
    text-align: left;
    list-style: none;
}

.stageCircleChecked, .stageCircle {
    display: flex;
    width: 31.3px;
    height: 30px;
    box-sizing: border-box;
    border-radius: 50%;
}

.stageCircleChecked {
    background: $japaneseLaurel;
    border-radius: 50%;
}

.stageCircle {
    border: solid 3px $lightGray;
}

.stageIcon {
    display: inline-block;
    vertical-align: top;
    padding: 0px 5px;
}

.stageChecked {
    color: $white;
    margin: auto;
    font-size: 17px;
}

.stageConnector {
    text-align: center;
    height: 41px;

    &:after {
        content:"";
        z-index: -1;
        border-left: 2px dashed $lightGray;
        font-size: 31px;
    }
}

.text {
    display: inline-block;
    text-align: left;
    color: $doveGray;
}

.textChecked {
    display: inline-block;
    text-align: left;
}

.message {
    margin-bottom: 0px;
    margin-top: 7px;
    width: 300px;
}

.onsiteDetails {
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 3px;
    margin-top: 3px;
}

.stageTimeStamp {
    margin-bottom: 5px;
}