@import './../../styles/_colors.scss';
@import './../../styles/_common.scss';

.container {
    margin: 0;
    width: 100%;
}

.border {
    border-top: 0.5px solid $gray;
    border-bottom: 0.5px solid $gray;
}

.grid {
    border: 1px solid $gray;
}

.tableNonBordered tr {
    padding: 0px 10px;
    display: block;
}

.tableNonBordered,
.tableNonBordered th,
.tableNonBordered td {
    border: none;
}

.tableNonBordered thead {
    background: #fff;
    display: block;
}

.tableNonBordered th {
    vertical-align: top;
    height: 42px;
    line-height: 42px;
    white-space: nowrap;
    box-sizing: content-box;
    text-align: left;
    padding: 0px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-weight: 600;
    font-size: 14px;
    outline: transparent;
    color: rgb(50, 49, 48);
    text-overflow: ellipsis;
    overflow: hidden;
}

.tableNonBordered tbody {
    overflow: hidden;
    display: block;
}

.tableNonBordered tbody tr:nth-child(even) {
    background: rgb(238, 238, 238);
}

.tableNonBordered td {
    color: rgb(96, 94, 92);
    min-height: 32px;
    line-height: 32px;
    white-space: nowrap;
    text-align: left;
    padding: 0px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 400;
    outline: transparent;
    box-sizing: border-box;
}

.tableNonBordered td i {
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
    color: rgb(0, 120, 212);
}

.tdNoRecordFound {
    text-align: center !important;
    font-weight: bold !important;
    min-width: 950px;
}

.gridParent {
    overflow: hidden;
    overflow-x: auto;
    padding-top: 30px;
}

.itemTitle,
h3 {
    font-weight: bold;
    line-height: 22px;
    font-size: 12px;
    margin: 0;
}

.itemDescription {
    font-size: 11px;
    line-height: 20px;
    white-space: pre-wrap;
}

.description {
    padding-left: 46px;
}

.itemMargin {
    margin: 0;
}

.offer {
    color: $black;
    width: 939px;
    margin: 3px;
    font-family: 'Segoe UI';
    color: $black;
}

.row {
    display: flex;
    flex-flow: row wrap;
}

.toolTip {
    margin-left: 9px;
    padding-bottom: 4px;
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.warningIcon {
    font-size: 14px;
    background: $white;
    padding-left: 10px;
    padding-right: 10px;
    color: $persianRed;
}

.collapsibleHeaderDesc {
    background-color: #FFFFFF;
}