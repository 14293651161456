@import './../../styles/_colors.scss';
@import './../../styles/_common.scss';

.borderTop {
    border-top: 2px solid $gray;
}

.borderBottom {
    border-bottom: 2px solid $gray;
}

.item {
    font-family: 'Segoe UI';
    font-size: 14px;
}

.button {
    cursor: pointer;
    font-size: 14px;
    height: 32px;
    line-height: 1.43;
    width: 120px;
}

.buttonCreateOrder {
    background-color: $black;
    color: $white;
    border-style: none;

    &:focus{
        outline-offset: 3px;
    }
}

.buttonBack {
    background-color: $white;
    border: 2px solid;
    color: $black;

    &:focus{
        outline-offset: 3px;
    }
}

.group:not(:last-child) {
    margin-bottom: 10px;
}

.lineItems {
    padding: 10px 0px;
}