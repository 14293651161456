@import "./../../styles/_colors.scss";

.serviceRequest {
    color: $white;
    font-size: 14px;
    width: 939px;
}

.header {
    align-items: center;
    display: flex;
    flex: 100%;
    justify-content: space-between;
    padding-right: 20px;
    width: 100%;
}

.order {
    display: flex;
    margin-right: 70px;
    white-space: nowrap;
}

.orderNumber {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 175px;
}

.orderNumberLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 175px;
}

.shippingLabelLink {
    justify-content: center;
    min-width: 175px;
}

.status {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    min-width: 130px;
}

.statusIcon {
    align-items: center;
    background: $japaneseLaurel;
    border: 1px solid $white;
    border-radius: 50%;
    color: $white;
    display: flex;
    font-size: 10px;
    height: 18px;
    justify-content: center;
    margin-left: 9px;
    width: 19px;
}

.statusState {
    font-weight: bold;
    margin-left: 9px;
    text-transform: capitalize;
}
.reasonSection{
    font-size: 12px;
    color: black;
    margin: 10px;
}
.reasonLabel {
    font-weight: bold;
    margin-top:7px;
}