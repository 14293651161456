@import "./../../styles/_colors.scss";

.checkMark {
  display: inline-block;
  width: 22px;
  height: 22px;
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  margin: 0 5px;
}

.checkMark_circle {
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: $japaneseLaurel;
  border-radius: 11px;
  left: 0;
  top: 0;
}

.checkMark_stem {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: $white;
  left: 11px;
  top: 6px;
}

.checkMark_kick {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: $white;
  left: 8px;
  top: 12px;
}

.expiredMark {
  background: $alizarinCrimson;
  border-radius: 50%;
  color: $white;
  font-size: 20px;
  font-weight: 900;
  height: 21px;
  margin: 0px 5px;
  padding-bottom: 2px;
  text-align: center;
  width: 22px;
}

.extensionButton {
  text-align: right;
}

.extensionWarranty {
  background-color: $black;
  font-size: 14px;
  line-height: 20px;
  height: 32px;
  width: 191px;
  color: $white;
  padding: 0 10px;

  &:hover {
    background-color: $black;
    color: $white;
  }
}

.fontSize {
  font-size: 14px;
  line-height: 20px;
  width: 100px;
}

.fontSizeSmall {
  font-size: 12px;
  line-height: 20px;
}

.fontBold {
  font-weight: bold;
}

.heading {
  font-size: 24px;
  line-height: 28px;
  color: $black;
  height: 28px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

.message {
  padding: 22px 0;
}

.planDetail {
  padding: 22px 0;
}

.plan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 25px;
  border: 1px solid $nobel;
  height: 50px;
  background: $alabaster;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.planWidth {
  width: 200px;
}

.status {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100px;
}

.statusContainer {
  display: flex;
  width: 170px;
}

.statusContainer img {
  margin: 0 3px;
}

.warranties {
  width: 940px;
  padding: 20px 0;
}

.warrantiesSec {
  width: 100%;
}

.infoMark {
  color: $black;
  font-size: 1.25rem;
  margin-left: 5px;
  vertical-align: middle;
  font-weight: 400;
  cursor: default;
}