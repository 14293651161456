@import './../../styles/_colors.scss';
@import './../../styles/_common.scss';

.hidden {
    display: none !important;
}

.displayBlock {
    display: block !important;
}

.textCenter {
    text-align: center !important;
}

.uploadButton {
    background-color: rgb(0, 120, 212);
    border: 2px solid;
    color: #fff;
    cursor: pointer;
    font-family: 'Segoe UI';
    font-size: 16.45px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1;
    max-height: 52px;
    max-width: 300px;
    min-height: 44px;
    min-width: 196px;
    overflow: hidden;
    padding: 0 10px;
}

.dvModal {
    min-width: 250px;
}

.dvModalHeader {
    padding: 15px;
    background-color: #333;
    color: #ffffff;
    line-height: 14px;
}

.dvModalHeader span {
    font-size: 16px;
    line-height: 16px;
}

.modalCloseIcon {
    cursor: pointer;
    color: white;
    position: absolute;
    font-weight: bold;
    font-size: 16px;
    right: 15px;
    top: 15px;
    line-height: 18px;
}

.dvModalBody {
    padding: 15px;
}

.gridSection {
    border: 1px solid #ccc;
    overflow: auto;
}

.tableNonBordered {
    border: none;
    width: 100%;
}

.tableNonBordered tr {
    border-bottom: 1px solid rgb(237, 235, 233);
    display: block;
}

.tableNonBordered tbody tr:nth-child(even) {
    background: rgb(238, 238, 238);
}

.tableNonBordered th,
.tableNonBordered td {
    border: none;
    text-align: left;
    min-height: 42px;
    line-height: 42px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    white-space: nowrap;
    outline: transparent;
    margin-top: 10px;
    margin-right: 30px;
    padding: 5px 15px;
}

.tableNonBordered thead {
    background: #fff;
    display: block;
}

.tableNonBordered th {
    box-sizing: content-box;
    color: rgb(50, 49, 48);
    font-size: 14px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
    margin-top: 0px;
}

.tableNonBordered tbody {
    max-height: calc(100vh - 300px);
    overflow: auto;
    display: block;
}

.tableNonBordered td {
    box-sizing: border-box;
    color: #000;
    font-size: 14px;
}

.tdNoRecordFound {
    text-align: center !important;
    font-weight: bold !important;
    min-width: 950px;
}

.add {
    color: green !important;
}

.update {
    color: blue !important;
}

.delete {
    color: red !important;
}

.status {
    color: #015201 !important;
    //color: orange !important;
}

.errorStatusText {
    overflow: hidden;
    width: 100px;
    text-align: center;
    color: red;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.processing {
    margin: auto;
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 10px !important;
    height: 10px !important;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

.modalToolTip {
    cursor: pointer;
    width: 100px;
    text-align: center;
    display: block;
}

.elementToolTip {
    cursor: default;
    display: block;
}

.successIcon,
.tableNonBordered td i.successIcon {
    font-size: 20px;
    color: green;
    cursor: pointer;
}

.errorIcon,
.tableNonBordered td i.errorIcon {
    font-size: 20px;
    color: red;
    cursor: pointer;
}

.warningIcon,
.tableNonBordered td i.warningIcon {
    font-size: 20px;
    color: orange;
    cursor: pointer;
}

.dvModalFooter {
    padding: 0px 10px 15px;
    text-align: right;
}

.mainButton {
    margin-right: 5px;
    margin-left: 15px;
}