@import "./../../styles/_colors.scss";

.body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px 10px 20px;
}

.buttonWrap {
  margin-top: auto;
}

.bottomPaddingOrderDetail {
  padding-bottom: 10em;
}

.marginRightPartnerForm {
  margin-right: 3em;
}

.cancelConfirmationModal {
  border: solid 1px $doveGray;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 20px 20px 10px 0px;
  justify-content: space-between;
}

.cancelDescription {
  font-weight: 300;
  line-height: 1.29;
  width: 645px;
}

.cancelOrder {
  align-items: center;
  align-self: flex-end;
  background: $black;
  color: $white;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-top: auto;
  width: 125px;

  &:hover {
    background: black;
  }
  &:focus::after {
    border-color: $white;
    border-width: 1.5px;
  }
}

.confirmButton {
  align-items: center;
  align-self: flex-end;
  background: $black;
  color: $white;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-top: auto;
  width: 135px;
  margin-right: 1em;

  &:hover {
    background: black;
  }
  &:focus::after {
    border-color: $white;
    border-width: 1.5px;
  }
}

.reConfirmButton {
  align-items: center;
  align-self: flex-end;
  background: $black;
  color: $white;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-top: auto;
  width: 160px;
  margin-right: 1em;

  &:hover {
    background: black;
  }
  &:focus::after {
    border-color: $white;
    border-width: 1.5px;
  }
}

.closeOrderButton{
  align-items: center;
  align-self: flex-end;
  background: $black;
  color: $white;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-top: auto;
  width: 125px;
  margin-right: 1em;

  &:hover {
    background: black;
  }
  &:focus::after {
    border-color: $white;
    border-width: 1.5px;
  }
}

.cancelTitle {
  font-weight: bold;
  margin-bottom: 14px;
}

.detail {
  line-height: 1.67;
  margin-bottom: 7px;
}

.detailLink {
  margin-top: auto;
}

.details {
  padding-bottom: 10px;
  width: 30%;
}

.deviceProblem {
  font-weight: bold;
}

.disabled {
  background-color: $gray;
  cursor: not-allowed;
}

.header {
  align-items: center;
  display: flex;
  flex: 100%;
  padding-right: 20px;
  justify-content: space-between;
  width: 100%;
}

.headerText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 600px;
}

.headerRight {
  min-width: 108px;
}

.label {
  display: inline-block;
  color: $doveGray;
}

.lineItem {
  color: $black;
  font-size: 14px;
}

.pizzaTracker {
  margin-bottom: 50px;
}

.partnerformButtons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin-bottom: 1em;
  margin-left: -15em;
}

.offerDescription {
  margin-bottom: 25px;
}

.orderDescription {
  margin-bottom: 30px;
}

.rejected {
  background: $alizarinCrimson;

  &:hover {
    background: $alizarinCrimson;
  }
}

.spinner {
  margin-right: 50px;
  margin-bottom: 5px;
}

.status {
  width: 50%;
}

.statusTitle {
  font-weight: bold;
  margin-bottom: 10px;
}

.status,
.details {
  display: flex;
  flex-direction: column;
}

.value {
  display: inline-block;
  font-weight: bold;
  margin-left: 10px;
}

.warningActions {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.warningContainer {
  display: flex;
}

.warningExit {
  cursor: pointer;
}

.warningExitIcon {
  color: $doveGray;
  font-size: 16px;
  font-weight: bold;
}

.warningIcon {
  color: $persianRed;
  font-size: 16px;
  margin-left: 24px;
}

.warningTexts {
  padding-left: 20px;
}
@media screen and (-ms-high-contrast: active) {
  .cancelOrder {
    -ms-high-contrast-adjust: none;
    color: $white;
    border: 1.5px solid $white;

    &:hover {
      -ms-high-contrast-adjust: none;
      color: $white;
    }
  }
}

@media screen and (-ms-high-contrast: black-on-white) {
  .cancelOrder {
    -ms-high-contrast-adjust: none;
    background-color: $white;
    color: $black;
    border: 1.5px solid $black;
    
    &:hover {
      -ms-high-contrast-adjust: none;
      color: $black;
      background-color: $white;
    }
  }
}
