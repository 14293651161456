@import "./../../styles/_colors.scss";

.header {
    align-items: center;
    background-color: $mineShaft;
    color: $white;
    display: flex;
    font-size: 18px;
    height: 18px;
    justify-content: space-between;
    left: 0;
    padding: 15px;
    position: fixed;
    right: 0;
    text-align: left;
    z-index: 999;
}

.feedback {
  color: $white;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
  border:none !important;
  text-decoration:none;
  i{
    display:flex;
  }
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    border-right: 2px solid $white;
    padding-right: 9px;
    vertical-align: middle;
    width: 120px;
}

.logoRight {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  align-items: center;
  a {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    border-color: $white;
    border-width: 2px;
    border-style: solid;
    margin-right: 10px;
  }
}

.logoRight .img {
    color: $white;
    font-size: 24px;
}

.logo>a {
    align-self: normal;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.logo span {
    color: inherit;
    padding-left: 9px;
    font-size: 16px;
    padding-bottom: 2px;
}

.messageBarContainer {
    position: fixed;
    top: 45px;
    width: 100%;
}

.singleTenantMessageBarContainer {
  position: relative;
  top: 48px;
  width: 100%;
  height:35px;
  background-color: #ecc5bf;
}

.singleTenantMessageBarContainer span {
  font-weight: bold;
  font-size: x-small;
}

.spnCheckStatus {
    display: block;
    margin-top: 5px;
}

.ms-ContextualMenu-Callout {
    box-shadow: none !important;
    border: none !important;
    outline: none;
}

.unclickable {
    pointer-events: none;
}

.userImg {
    width: 24px;
    height: 24px;
    margin-left: 10px;
}

.userName {
    font-size: 12px;
}

@media screen and (-ms-high-contrast: black-on-white) {
    .logoRight {
        a {
            border-color: $black;
        }
    }
    .logoRight .img {
        -ms-high-contrast-adjust: none;
        color: $black;
    }
}

@media screen and (-ms-high-contrast: white-on-black) {
    .logoRight {
        a {
            border-color: $white;
        }
    }
    .logoRight .img {
        -ms-high-contrast-adjust: none;
        color: $white;
    }
}