@import './../../styles/_colors.scss';

.bodyContainer {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: left;
    margin-bottom: 70px;
    align-self: center;
    width: 940px;
}

.sectionContainer {
    border-bottom: 1px solid $nobel;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}
