@import "./../../styles/_colors.scss";
.hidden {
    display: none;
}

.buttonContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.startOver {
    background-color: $white;
    border: 2px solid;
    color: $black;
    cursor: pointer;
}

.container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 1003px;
}

.dataSection {
    display: flex;
    margin-right: 25px;
}

.details {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
}

.deviceContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.deviceImage {
    height: 60px;
    margin-right: 30px;
    width: 80px;
}

.deviceDetailsSection {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
}

.headerTitle {
    color: $black;
    font-size: 30px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    height: 28px;
    letter-spacing: normal;
    line-height: 0.93;
}

.headSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    min-width: 800px;
    width: 94%;
}

.title {
    color: $black;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 20px;
    letter-spacing: normal;
    line-height: 1.43;
    margin-right: 10px;
}

.tooltip {
    height: 22px;
    width: 22px;
    margin-left: 5px;
}

.value {
    color: $black;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    height: 20px;
    letter-spacing: normal;
    line-height: 1.43;
    min-width: 40px;
}

.values {
    display: flex;
    font-weight: bold;
}

.deviceDetailsSection .values {
    margin-top: -1px;
}

.SdtSection img {
    vertical-align: top;
}