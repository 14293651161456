@import "./../../styles/_colors.scss";

.container{
    border: 1px $waikawaGray solid;
    box-shadow: -1px 1px $nobel;
    display: flex;
    flex-direction: column;
    color: $black;
    min-width: 350px;
    position: absolute;
    background-color: $white;
    right: 0;
    top: 47px;
}

.closeContainer, .minimizeContainer {
    width: 25px;
    height: 25px;
    margin: 0 5px 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
        background-color: $nobel;
        cursor: pointer;
    }
}

.closeIcon{
    user-select: none;
    font-size: 16px;
}

.formContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px 10px 5px 10px;
}

.headerContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 10px 5px 10px;
}

.hidden{
    display: none;
}

.iconsContainer{
    display: flex;
    flex-direction: row;
}

.inputs{
    width: 100%;
    padding: 10px 0 10px 0;
}

.minimize{
    height: 35px;
    position: fixed;
}

.referenceNumberContainer{
    max-width: 300px;
    font-size: 14px;
    min-height: 100px;
    align-self: center;
    display: flex;
}

.referenceNumber{
    align-self: center;    
}

.screenshot{
    align-self: center;
    width: 300px;
    height: auto;
}

.submitContainer{
    display: flex;
    flex-direction: row;
}

.submitButton{
    width: fit-content;
    margin: 10px 0 10px 0;
}

.title{
    justify-content: center;
    display: flex;
    font-size: 20px;
    font-weight: 600;
}