@import "styles/colors", "styles/common", "styles/mixin";

html {
  height: 100%;
  min-width: 1024px;
}

body {
  @include font-smoothing();
  font-family: $font-stack-all;
  height: 100%;
  margin: 0;
  padding: 0;
}

code {
  font-family: $font-stack-minimum;
}

#root {
  height: 100%;
}