@import './../../styles/_colors.scss';
@import './../../styles/_common.scss';

.addEditRow {
    margin-top: 10px;
}

.container {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.pagination {
    float: left;
    margin-top: 25px;
    width: 100%;
    text-align: center;
}

.pagination div {
    display: inline-block;
    cursor: pointer;
    color: black;
    text-decoration: none;
    transition: background-color .3s;
    height: 32px;
    width: 32px;
    text-align: center;
    margin: 0px 5px;
    font-size: 14px;
    line-height: 32px;
}

.pagination div button {
    height: 32px;
}

.pagination div.active {
    background-color: rgb(0, 120, 212);
    color: white;
    border: 1px solid rgb(0, 120, 212);
    height: 32px;
    width: 32px;
    border-radius: 50%;
    text-decoration: underline;
    font-weight: bold;
}

.countDisplay {
    display: block;
    text-align: center;
    margin-top: 10px;
    float: left;
    width: 100%;
}

.countDisplayText {
    color: rgb(96, 94, 92);
    font-size: 17px;
}

.gridParent,
.toolbarBlock {
    overflow: hidden;
    overflow-x: auto;
}

.toolbarBlock {
    padding: 0;
    border-bottom: 1px solid #edebe9;
}

.dvSearchBlock {
    display: inline-block;
    width: 97%;
    text-align: left;
    overflow: hidden;
    height: 32px;
    background-color: #eeeeee;
    padding: 5px 10px;
    position: relative;
}

.dvSearchBlock i {
    float: left;
    margin-right: 5px;
    font-size: 18px;
    line-height: 32px;
    color: #605e5c;
}

.dvSearchBlock .dvSearchTextBlock {
    float: left;
    width: calc(100% - 23px);
}

.dvSearchBlock div,
.dvSearchBlock input[type=text] {
    border: none;
    background-color: #eeeeee;
}

.dvSearchBlock .dvRolesBlock {
    position: absolute;
    width: 125px;
    right: 115px;
    margin-right: 10px;
}

.dvSearchBlock .dvRolesBlock select {
    width: 125px;
    height: 32px;
    padding: 5px;
    border: none;
}

.dvSearchBlock .dvTiersBlock {
    position: absolute;
    width: 60px;
    right: 36px;
    margin-right: 10px;
}

.dvSearchBlock .dvTiersBlock select {
    width: 60px;
    height: 32px;
    padding: 5px;
    border: none;
}

.dvSearchBlock .dvClearSearchBlock {
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    right: 10px;
    line-height: 30px;
    cursor: pointer;
}

.tableNonBordered tr {
    border-bottom: 1px solid rgb(237, 235, 233);
    padding: 0px 10px;
    display: block;
}

.tableNonBordered,
.tableNonBordered th,
.tableNonBordered td {
    border: none;
}

.tableNonBordered thead {
    background: #fff;
    display: block;
}

.tableNonBordered th {
    vertical-align: top;
    height: 42px;
    line-height: 42px;
    white-space: nowrap;
    box-sizing: content-box;
    text-align: left;
    padding: 0px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-weight: 600;
    font-size: 14px;
    outline: transparent;
    color: rgb(50, 49, 48);
    text-overflow: ellipsis;
    overflow: hidden;
}

.tableNonBordered tbody {
    overflow: hidden;
    display: block;
}

.tableNonBordered tbody tr:nth-child(even) {
    background: rgb(238, 238, 238);
}

.tableNonBordered td {
    color: rgb(96, 94, 92);
    min-height: 42px;
    line-height: 42px;
    white-space: nowrap;
    text-align: left;
    padding: 0px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 400;
    outline: transparent;
    box-sizing: border-box;
}

.tableNonBordered td i {
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
    color: rgb(0, 120, 212);
}

.tdNoRecordFound {
    text-align: center !important;
    font-weight: bold !important;
    min-width: 950px;
}

.commonFocus {
    &:focus {
        outline-offset: 3px;
    }
}

.grid {
    border: 1px solid gray;
    padding: 20px 20px;
}

.header {
    color: #000000;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 1.17;
    margin-bottom: 19px;
    margin-top: 19px;
}

.label {
    float: right;
}

.labelRequired:after {
    color: red;
    content: ' *';
}

.labelContainer {
    padding-right: 2px;
    padding-left: 2px;
}

.spinner {
    margin: 20px;
}

.tiers {
    display: inline-block;
}

.spinnerStyle {
    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
}

.actionContainer {
    align-items: center;
    display: inline-flex;
    height: 24px;
    justify-content: space-between;
}

.settings {
    display: inline-flex;
    float: right;
}

.addNewButton {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.loading {
    margin: auto;
}

.modalToolTip {
    height: 40px;
    display: block;
    text-align: center;
    line-height: 50px;
    margin-top: -8px;
}

.modalToolTipIcon {
    cursor: pointer;
    color: black;
    font-weight: bold;
    font-size: 25px;
    vertical-align: top;
}

.deleteIcon i {
    color: red !important;
}

.actionContainer .modalToolTip {
    margin: 0;
}

.checkboxHeader {
    padding-left: 0;
    padding-right: 2px;
}

.checkboxData {
    display: inline-block;
    width: auto;
}

.inviteMessage {
    font-size: 15px;
    margin-bottom: 20px;
    text-align: justify;
}