@import "./../../styles/_colors.scss";

.collapsible.active,
.collapsible:hover,
.collapsible:focus{
  background-color: $denimBlue;
}

.collapsible {
  background-color: #6E6E6E;
  color: $white;
  cursor: pointer;
  padding: 5px 15px;
  border: none;
  text-align: left;
  outline: none;
  font-size: 14px;
  margin-bottom: 10px;
}

.faqItem {
  margin-bottom: 10px;
  button{
    width:-webkit-fill-available;
    margin-bottom: 0px !important;
  }
}

.items {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }

  i {
    display: none;
  }
}

.item {
  flex: 1;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0;
}

.itemDetail {
  padding: 5px 15px;
}