@import './../../styles/_colors.scss';

.address {
    margin-bottom: 20px;
}

.breadcrumbs {
    margin-bottom: 30px;
}

.close {
    background: $black;
    margin-bottom: 20px;
    width: 120px;
    
    &:hover {
        background: $black;
    }
}

.detailSection {
    font-size: 24px;
    margin: 20px 0px;
    font-weight: normal;
}

.errorOccurred {
    color: $persianRed;
}

.faq {
    height: 20px;
    margin-left: 10px;
    width: 20px;
}

.page {
    align-self: center;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    font-size: 14px;
    width: 100%;
}

.pageBody {
    align-self: center;
    width: 939px;
}

.pageTitle {
    font-size: 30px;
    margin-bottom: 20px;
}

.pageType {
    border-bottom: 1px solid $doveGray;
    padding-bottom: 20px;
}

.orderConfirmation {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.orderDetail {
    align-items: center;
    display: flex;
    margin-right: 50px;
}

.orderDetailLabel {
    margin-right: 10px;
}

.orderDetailValue {
    font-weight: bold;
}

.orderDetails {
    display: flex;
    margin-bottom: 40px;
}

.orderSummary {
    border-top: 1px solid $doveGray;
    padding-top: 20px;
}

.orderSummaryItems {
    border-top: 1px solid $doveGray;
    border-bottom: 1px solid $doveGray;
    padding: 10px 20px;
    width: 800px;
}

.orderSummaryItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
}

.orderSummaryItemPrice {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    width: 90px;
}

.orderSummaryItemsTitle {
    font-weight: bold;
    margin-bottom: 10px;
}

.orderSummaryLine {
    font-weight: bold;
    margin-bottom: 20px;
}

.orderSummaryTotal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px 20px;
    width: 800px;
}