@import "./../../styles/_colors.scss";

.manageUser {
    min-width: 0;
    background: $mineShaft;
    color: $white;
    padding: 0;
  }
  
  .manageUserMenu {
    background: $mineShaft;
    span {
      color: $white;
  
    }
    a, button {
      &:focus, &:hover {
        background: $azureRadiance !important;
        color: $white;
        }
      &:active {
        background: $azureRadiance !important;
        color: $white !important;
      }
    }
  }

  .manageUser:focus, .manageUser:hover {
    background: $mineShaft;
    color: $white;
  }

  .manageUser:focus::after {
    border-color: $azureRadiance !important;
  }