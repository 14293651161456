@import "./../../styles/_colors.scss";

.confirm {
    align-self: flex-end;
    background: $white;
    border: 2px solid $black;
    color: $black;
    text-transform: capitalize;
    width: 120px;

    &:hover {
        background: $white;
        color: $black;
    }
    &:disabled{
        background-color: $nobel;
        color: $white;
        border: none;
    }
}

.detailName {
    font-weight: bold;
}

.detailValue {
    font-weight: bold;
}

.disabled {
    cursor: not-allowed;
}

.disabledButton {
    background-color: $gray;
    border: none;
    color: $white;
    cursor: not-allowed;
}

.errorInput {
    border-color: $persianRed !important;
}

.errorMsg {
    width: 400px;
}

.icon {
    width: 55px;
    height: 57px;
}

.input {
    border: 2px solid rgba(0,0,0,0.4);
    box-sizing: border-box;
    display: block;
    height: 32px;
    margin-bottom: 10px;
    padding: 10px;
}

.image {
    height: 108px;
    margin-right: 30px;
    min-width: 103px;
    width: 103px;
}

.main {
    align-self: flex-start;
    display: flex;
    font-size: 12px;
}

.serviceResponse {
    display: flex;
    justify-content: space-between;
    width: 165px;
}

.spinnerStyle {
  margin-left: 10px;
}

.submitAndSpinnerContainer{
    display: flex;
    flex-direction: row;
}

.table {
    line-height: 1.67;
}

.tableHeaders {
    border-bottom: 1px solid $nobel;
    color: $doveGray;
    display: flex;
    padding: 10px 0px;
    width: 670px;
}

.tableHeader {
    display: flex;
    width: 250px;
}

.tableRow {
    display: flex;
    padding: 10px 0px;
}

.tableColumn {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    width: 222px;
}