@import './../../styles/_colors.scss';
@import './../../styles/_common.scss';

.bodyLeft, .bodyRight {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.closeButton, .printButton {
    background: $black;
    color: $white;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    margin-left: 15px;
    text-transform: capitalize;
    width: 125px;

    &:hover {
        background: $black;
        color: $white;
    }
}

.columnLeft, .columnRight {
    width: 50%;
}

.columnLeft {
    display: flex;
    justify-content: flex-end;
    text-align: right;
}

.columnRight {
    align-self: flex-end;
    display: flex;
    height: 100%;
    justify-content: flex-end;
}

.detail {
    margin-bottom: 10px;
}

.divider {
    border-top: 1px solid $gray;
    margin: 10px 0px;
}

.image {
    margin: auto;
    max-height: 100px;
    width: 100px;
}

.imageAlignment
{
    margin: 10px ;
    height: 95px ;
    width: 120px ;
}

.label {
    font-weight: bold;
}

.main {
    font-size: 14px;
    padding: 10px;
}

.price {
    display: flex;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: auto;
}

.priceUnit {
    margin-left: 5px;
}

.row {
    display: flex;
}

.rowSpacing {
    margin-bottom: 10px;
}

.section {
    border-bottom: 1px solid $gray;
    border-top: 1px solid $gray;
    box-sizing: border-box;
    display: flex;
    padding: 10px 0px;
    width: 100%;
}

.title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
}

.topHeader {
    margin-bottom: 20px;
}

.topHeader, .bottomHeader {
    display: flex;
    justify-content: flex-end;
}

.weight {
    font-weight: bold;
}