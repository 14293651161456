@import "./../../styles/_colors.scss";

.bodyContainer {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: left;
  margin-bottom: 70px;
  align-self: center;
  width: 940px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 20px;
}

.createOrderButton {
  align-self: flex-end;
  background-color: $black;
  color: $white;
  font-size: 14px;
  height: 32px;
  line-height: 20px;
  padding: 0 10px;
  width: 125px;
  &:hover {
    background-color: $black;
    color: $white;
  }
  &.disabled {
    cursor: not-allowed;
    background-color: $gray;
  }
  &:focus:after {
    border-color: $white;
  }
}

.emptyOrderMessage {
  margin: auto;
}

.label {
  color: $black;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  letter-spacing: normal;
  line-height: 1.17;
  margin-bottom: 19px;
  margin-top: 19px;
}

.orderHistory {
  margin-top: 10px;
}

.sectionContainer {
  border-bottom: 1px solid $nobel;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.tableContainer {
  margin-bottom: 10px;
}

@media screen and (-ms-high-contrast: active) {
  .createOrderButton {
    -ms-high-contrast-adjust: none;
     background-color: $white ;
      color: $black;

&:hover {
  -ms-high-contrast-adjust: none;
  background: $white;
  color: $black;
}
}
}
@media screen and (-ms-high-contrast: black-on-white) {
  .createOrderButton {
    -ms-high-contrast-adjust: none;
    background-color: $black;
    color:$white;

    &:hover {
      -ms-high-contrast-adjust: none;
      background: $black;
      color: $white;
    }

    &:focus::after {
      -ms-high-contrast-adjust: none;
      border-color: $white;
      border-width: 1.5px;
    }
    &:active {
      -ms-high-contrast-adjust: auto;
      border-color: $white;
    }
  }
}
