@import './../../styles/_colors.scss';

.bodyContainer {
  align-self: center;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: left;
  margin-bottom: 70px;
  width: 940px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 20px;
}

.createOrderButton {
  align-self: flex-end;
  background-color: $black;
  color: $white;
  font-size: 14px;
  height: 32px;
  line-height: 20px;
  padding: 0 10px;
  width: 125px;

  &:hover {
    background-color: $black;
    color: $white;
  }

  &:disabled {
    background-color: $gray;
    cursor: not-allowed;
  }
}

.label {
  color: $black;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  letter-spacing: normal;
  line-height: 1.17;
  margin-bottom: 19px;
  margin-top: 19px;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
}

.sectionSeparator{
  border-top: 1px solid $nobel;
  margin-top: 20px;
}

.tableContainer {
  margin-bottom: 10px;
}


.editContainer {
  display: inline-flex;
  align-items: center;
}
.editIcon {
  margin-left: 40px;
  vertical-align: middle;
}

.header {
  display: inline-flex;
}

.headerContainer {
  color: $black;
  margin-bottom: 20px;
  padding-top: 10px;
}
