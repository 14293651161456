@import "./../../styles/_colors.scss";

.breadcrumbs {
    padding-bottom: 25px;
    padding-left: 28px;
    margin-top: 60px;
}

.commonLayout {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.commonLayoutContent {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    flex-direction: column;
}

.focusWithin {
    align-items: center;
    background: $mercury;
    border: 1px dashed $black;
    display: flex;
    font-size: 15px;
    height: 58px;
    justify-content: center;
    min-width: 10%;
    overflow: auto;
    padding: 5px;
    position: absolute;
    text-align: center;
    vertical-align: center;
    width: 100%;
    z-index: 1000;
}

.skipLinks {
    left: -999px;
    position: absolute;
    top: auto;
    min-width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
}

.skipLink {
    margin-right: 10px;
    white-space: nowrap;

    &:first-child {
        margin-left: 10px;
    }
}