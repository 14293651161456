// Color name are generated with http://chir.ag/projects/name-that-color
$alabaster: #f8f8f8;
$alizarinCrimson: #e02020;
$azureRadiance: #006dff;
$black: #000;
$blueViolet: #5564B9;
$cornflowerBlue: #6195ed;
$denimBlue: #0b67b3;
$doveGray: #636262;
$expGray: #969696;
$gray: #ccc;
$grey: $gray;
$japaneseLaurel: #027c00;
$lightBlue: #d2f4ff;
$lightGray: #b2b2b2;
$malibu: #61dafb;
$mercury: #e6e6e6;
$mineShaft: #333;
$nobel: #b4b4b4;
$persianRed: #d4322f;
$red: red;
$tuatara: #323130;
$waikawaGray: #666;
$white: #ffffff;
:export {
  waikawaGray: $waikawaGray;
  black: $black;
}
