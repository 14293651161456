@import "./../../styles/_colors.scss";

.footer {
  align-items: center;
  background-color: $mineShaft;
  color: $white;
  display: flex;
  font-size: 14px;
  padding: 18px 110px;
  text-align: left;
}

.link {
  border-right: 1px solid $white;
  padding: 0 12px;
}

.linkContainer {
  display: flex;
  width: 64%;
}

.link:last-child {
  border-right: 0;
}

.linkText {
  color: $white;
  text-decoration: none;
  cursor: pointer;
}
