@import './../../styles/_colors.scss';
@import './../../styles/_common.scss';

.container {
    align-self: center;
    margin: 4px;
    width: 940px;
    justify-content: center;
}

.deviceInfo {
    border-bottom: 2px solid $gray;
    margin-bottom: 10px;
    padding-bottom: 15px;
}
