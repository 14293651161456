$font-stack-all: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
$font-stack-minimum: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

.row {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    align-items: flex-start;
    box-sizing: border-box;
}

.container {
    display: flex;
    flex-direction: column;
    margin: 4px;
    width: 100%;
}

.col {
    flex-basis: 100%;
    box-sizing: border-box;
}

@for $i from 1 through 12 {
    .col#{$i} {
        flex: 1 1 (100/ (12 / $i)) * 1%;
        box-sizing: border-box;
    }
}

@for $i from 1 through 12 {
    .colOffSet#{$i} {
        margin-left: (100/ (12 / $i)) * 1%;
    }
}

@for $i from 1 through 30 {
    .marginBottom#{$i} {
        margin-bottom: ($i) * 1px;
    }
}

@for $i from 1 through 30 {
    .marginTop#{$i} {
        margin-top: ($i) * 1px;
    }
}

@for $i from 1 through 30 {
    .marginVertical#{$i} {
        margin-top: ($i) * 1px;
        margin-bottom: ($i) * 1px;
    }
}

@for $i from 1 through 30 {
    .paddingLeft#{$i} {
        padding-left: ($i) * 1px;
    }
}

@for $i from 1 through 30 {
    .paddingRight#{$i} {
        padding-right: ($i) * 1px;
    }
}

@for $i from 1 through 30 {
    .paddingTop#{$i} {
        padding-top: ($i) * 1px;
    }
}

@for $i from 1 through 30 {
    .paddingVertical#{$i} {
        padding-top: ($i) * 1px;
        padding-bottom: ($i) * 1px;
    }
}

.button {
    cursor: pointer;
    font-size: 14px;
    height: 32px;
    line-height: 1.43;
    width: 125px;
}

.buttonPrimary {
    background-color: $black;
    color: $white;
    border-style: none;

    &:disabled {
        background-color: $gray;
        cursor: not-allowed;
    }
}

.buttonSecondary {
    background-color: $white;
    border: 2px solid;
    color: $black;
}

.h2Heading{
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: $black;
}

.h3Heading{
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
}

.link {
    color: $blueViolet;
}