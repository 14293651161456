@import './../../styles/_colors.scss';
@import './../../styles/_common.scss';

.buttonAlign {
    align-self: flex-end;
    align-items: center;
    margin-top: 29px;
}

.continueButton {
    cursor: pointer;
    font-size: 14px;
    height: 32px;
    line-height: 1.43;
    width: 125px;
    &:focus::after {
        border-color: $white;
        border-width: 1.5px;
        border-style: solid;
    }
}

.buttonContainer {
    display: flex;
    align-content: flex-end;
    align-items: center;
    margin-top: 30px;
    padding-top: 10px;
    border-top: 1px solid $nobel;
}

.popup {
    min-width: 600px;
    max-width: 700px;
    min-height: 350px;
}

.searchContainer {
    border-bottom: 1px solid $gray;
    margin: auto;
    padding-bottom: 30px;
}
.spinnerStyle {
    margin: 4px;
    align-self: flex-end;
}

.optionContainer {
    display: flex;
    align-items: baseline;
    flex-flow: column;
}

.option {
    flex: 1;
    padding-left: 30px;
}
.optionLabel {
    font-weight: 600;
}

.spinnerStyle {
    display: inline-flex;
    align-self: baseline;
}
