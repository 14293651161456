@import "./../../styles/_colors.scss";

.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dashboardContent {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  flex-direction: column;
}

.desc {
  font-size: 14px;
  padding: 10px 0;
  line-height: 19.2px;
}

.firstSection {
  border-bottom: 2px solid $grey;
  padding-bottom: 30px;
  align-self: start;
}

.firstSection label {
  color: $black;
}

.iconContainer img {
  width: 40px;
  height: 40px;
}

.iconContainerFaqs img {
  width: 32px;
  height: 32px;
}

.iconContainerUser img {
  width: 32px;
  height: 32px;
}

.iconsLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 30px 0;
}

.links {
  font-size: 20px;
  padding: 10px 0;
  line-height: 28px;
}

.middleSection {
  max-width: 940px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.ms-Breadcrumb-listItem {
  color: $azureRadiance;
  font-size: 13px;
  line-height: 20px;
}

.order {
  font-size: 16px;
  color: $azureRadiance;
  padding: 10px 0;
  line-height: 16px;
}

.order a {
  text-decoration: none;
  cursor: pointer;
  color: $azureRadiance;
}

.secondSection {
  padding: 10px 0 40px 0;
  align-self: start;
}

.textLink {
  font-size: 16px;
  color: $azureRadiance;
  line-height: 16px;
  padding-top: 7px;
  cursor: pointer;
}

.thirdSection {
  border-top: 2px solid $grey;
  padding: 30px 0 20px 0;
  align-self: start;
}

.title {
  font-size: 30px;
  line-height: 28px;
  height: 28px;
  margin: 0 0 29px 0;
  font-weight: normal;
}

.user {
  font-size: 34px;
  padding: 7px 0;
  letter-spacing: 0;
  font-weight: normal;
  margin: 0;
}

