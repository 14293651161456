@import "./../../styles/_colors.scss";
.textCenter {
    text-align: center;
}

.dvDisplayResult {
    margin-left: 10px;
}

.dvModal {
    min-width: 250px;
}

.dvModalHeader {
    padding: 15px;
    background-color: #333;
    color: #ffffff;
    line-height: 14px;
}

.dvModalHeader span {
    font-size: 16px;
    line-height: 16px;
}

.dvModalHeader .modalCloseIcon {
    cursor: pointer;
    color: white;
    position: absolute;
    font-weight: bold;
    font-size: 16px;
    right: 15px;
    top: 15px;
    line-height: 18px;
}

.dvModalBody {
    padding: 20px;
    padding-top: 0px;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.tableBordered {
    border: 1px solid #ddd;
}

.tableNonBordered,
.tableNonBordered th,
.tableNonBordered td {
    border: none;
    margin-top: 10px;
    margin-right: 30px;
}

.tableNonBordered thead {
    background: #fff;
    display: block;
}

.tableNonBordered th {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 15px;
}

.tableNonBordered tbody {
    max-height: calc(100vh - 200px);
    overflow: hidden;
    overflow-y: auto;
    display: block;
}

.tableNonBordered td {
    text-align: left;
    font-size: 14px;
    padding: 5px 15px;
    color: #000;
    padding-left: 18px;
}

.tdTestRunName {
    min-width: 180px;
}

.success {
    color: #00ab44 !important;
    min-width: 80px;
}

.error {
    color: #ff0000 !important;
    min-width: 80px;
}

.container {
    border: 1px $waikawaGray solid;
    box-shadow: -1px 1px $nobel;
    display: flex;
    flex-direction: column;
    color: $black;
    min-width: 350px;
    position: absolute;
    background-color: $white;
    right: 0;
    top: 47px;
}

.closeContainer,
.minimizeContainer {
    width: 25px;
    height: 25px;
    margin: 0 5px 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: $nobel;
        cursor: pointer;
    }
}

.closeIcon {
    user-select: none;
    font-size: 16px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px 10px 5px 10px;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 10px 5px 10px;
}

.hidden {
    display: none;
}

.iconsContainer {
    display: flex;
    flex-direction: row;
}

.inputs {
    width: 100%;
    padding: 10px 0 10px 0;
}

.minimize {
    height: 35px;
    position: fixed;
}

.referenceNumberContainer {
    max-width: 300px;
    font-size: 14px;
    min-height: 100px;
    align-self: center;
    display: flex;
}

.referenceNumber {
    align-self: center;
}

.screenshot {
    align-self: center;
    width: 300px;
    height: auto;
}

.submitContainer {
    display: flex;
    flex-direction: row;
}

.submitButton {
    width: fit-content;
    margin: 10px 0 10px 0;
}

.title {
    justify-content: center;
    display: flex;
    font-size: 20px;
    font-weight: 600;
}

.modalIcon {
    cursor: pointer;
    color: black;
    margin-top: 2px;
    font-weight: bold;
    font-size: 20px;
}