.spinnerContainer {
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: center;
    text-align: center;
}

.spinnerStyle {
    margin: 2px;
}
