@import './../../styles/_colors.scss';

.bulletLabel {
    margin-left: 25px;
    font-weight: bold;
    font-size: 18px;
}

.bullets {
    font-weight: normal;
    font-size: 14px;
    list-style: disc;
    margin: 15px 0px;
}

.secondBullet {
    margin:0px;
    font-weight: normal;
    font-size: 14px;
    list-style: disc;
}

.secondBulletLabel{
    margin-left: 25px;
    font-weight: bold;
    font-size: 18px;
}
.secondSection{
    margin-top: 15px;
}

.body {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    margin: auto;
    width: 940px;
}

.closeButton {
    align-items: flex-end;
    align-self: flex-end;
    background: $black;
    box-sizing: border-box;
    color: $white;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: normal;
    height: 32px;
    justify-content: flex-start;
    margin-top: 10px;    
    width: 70px;
}

.section {
    border-bottom: 1px solid $black;
    border-top: 1px solid $black;
}

.steps {
    font-size: 20px;
}

.title {
    font-size: 24px;
    margin-bottom: 10px;
}