@import './../../../styles/_colors.scss';
@import './../../../styles/_common.scss';

.hidden {
    display: none;
}

.accessoryContainer {
    border-top: 1px solid $nobel;
}

.accessorySection {
    padding-bottom: 20px;
    padding-top: 15px;
}

.changeSelection {
    background-color: $white;
    border: 2px solid;
    color: $black;
    cursor: pointer;
}

.comboBox {
    display: inline-block;
    width: 250px;
}

.continueButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $nobel;
}

.continueButton {
    align-self: flex-end;
    background-color: $black;
    color: $white;
    font-size: 14px;
    height: 32px;
    line-height: 20px;
    padding: 0 10px;
    width: 125px;

    &:hover {
        background-color: $black;
        color: $white;
    }

    &:focus::after {
        content: '';
        border-color: $azureRadiance !important;
    }

    &:disabled {
        background-color: $gray;
        cursor: not-allowed;
    }
}

.datePickerWidth {
    width: 250px;
}

.dateOfPurchase {
    margin: 20px 0;
}

.deleteIcon {
    cursor: pointer;
    font-size: 17px;
    height: 20px;
    width: 20px;
}

.dropZone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: $expGray;
    border-style: dashed;
    background-color: lightGray;
    color: $waikawaGray;
    outline: none;
    transition: border 0.24s ease-in-out;
}

.eligibilityToolTip {
    margin-left: 5px;
    width: 20px;
    height: 20px;
}

.error {
    margin-top: 10px;
}

.fileFormat {
    font-size: 12px;
    font-weight: 200;
}

.headerSection {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.image {
    display: inline-block;
    border: 1px solid $grey;
    padding: 5px;
    margin: 5px;
}

.image button {
    margin-left: 10px;
}

.imageContainer {
    display: block;
}

.inlineError {
    color: $persianRed;
    display: block;
    font-size: smaller;
}

.label {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    display: inline-flex;
    margin-left: 27px;
}

.menuItemHeader {
    color: $black;
}

.notSupportedText {
    color: $persianRed;
}

.parentSection {
    padding-bottom: 20px;
}

.parentOrPartSection {
    display: inline-block;
}

.pstHeaderContainer {
    padding: 0;
}

.repairItemContainer {
    min-width: 812px;
}

.rrtContent {
    font-size: 14px;
    font-weight: 600;
    color: $black;
    padding: 15px 0px 5px;
    width: 100%;
}

.sectionSeparator {
    border-top: 1px solid $nobel;
}

.spinner {
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 6px;
    vertical-align: bottom;
}

.textAreaLabel {
    color: $tuatara;
    display: block;
    font-weight: 600;
    padding: 5px 0 5px 0;
}

.partSelectionCombo{
    min-width: 290px;
}

.onDemandSelectionCombo{
    min-width: 340px;
}

.partSelectionDiv{
    min-width: 420px;
    float:left;
}

.elementToolTip{
    margin-top: 27px;
    float: left;
}

.problemTypeToolTip{
    margin-top: -57px;
    float: left;
    margin-left: 104px;
}

.cruElementToolTip{
    margin-top: 27px;
    float: left;
    margin-left: -105px;
}

.toolTipIcon{
    width: 20px;
    height: 20px;
}

.toolTip {
    margin: 5px;
}

.warningIcon {
    color: $persianRed;
    font-size: 16px;
    margin-left: 5px;
}

.withBorder {
    border-bottom: 1px solid $nobel;
}

.popCheckboxDiv {
    float: left;
}

.cruCheckboxElementToolTip{
    float:left;
    margin-top: 29px;
    margin-left: 8px;
}
.probToolTip{
    height: 20px;
    width: 20px;
}
.probComboBox{
    position: absolute;
    margin-top: -62px;
    margin-left: 94px;
}

.cruInventoryPopCheckedRequired::after{
    content: " *";
    color: rgb(164, 38, 44);
    padding-left: 4px;
    font-size: 14px;
    font-weight: 600;
}