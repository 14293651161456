@import "./../../styles/_colors.scss";

.toggle{
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  flex: 1 100%;
  align-items: center;
  flex-basis: 60px;
}

.chevron{
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.backgroundColor {
  &.alabaster {
    background-color: $alabaster;
  }

  &.black {
    background-color: $black;
  }

  &.doveGray {
    background-color: $doveGray;
  }

  &.white {
    background-color: $white;
  }

  &.nobel {
    background-color: $nobel;
  }

  &.doveGray {
    background-color: $doveGray;
  }

  &.denimBlue {
    background-color: $denimBlue;
  }
}

.chevron {
  &.black {
    color: $black;
  }

  &.white {
    color: $white;
  }

  &.nobel {
    color: $nobel;
  }

  &.doveGray {
    color: $doveGray;
  }
}

