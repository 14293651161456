@import "./../../styles/_colors.scss";

.body {
  font-size: 12px;
}

.section{
  display: flex;
  flex-direction: column;
  border: solid 1px $nobel;
}