@import "./../../styles/_colors.scss";
@import "./../../styles/_common.scss";

.boldText {
  font-weight: 700;
}

.button {
  float: right;
}

.deviceDetail {
  color: $black;
  display: flex;
  font-size: 14px;
  line-height: 20px;
}

.edit {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 66px;
  background: transparent;
  color: $black;
}

.edit i {
  color: $black;
  font-size: 14px;
}

.edit:hover {
  background: transparent;
  color: $black;
}

.errorMessage {
  color: $persianRed;
  padding: 15px 0 0;
}

.fields {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.registerDevice {
  padding: 27px 0;
}

.registerDeviceTitle {
  margin-bottom: 30px;
}

.registerDeviceDesc {
  margin-bottom: 17px;
}

.registerInputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 28px;
  width: 350px;
  &:last-child {
    padding-left: 140px;
  }
}

.submit {
  background-color: $black;
  font-size: 14px;
  line-height: 20px;
  height: 32px;
  width: 130px;
  margin-top: 28px;
}

.submitAndSpinnerContainer {
  display: flex;
  flex-direction: row;
}

.spinner {
  margin-top: 28px;
  padding-left: 10px;
}

.titleHeading {
  font-size: 24px;
  line-height: 28px;
  color: $black;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
